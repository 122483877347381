import API from "@/api/index";

export default {
  getList: (params) => {
    return API.requestGet(
      {
        params,
        url: "user/friends/page"
      }
    )
  }
};
